@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html, body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
  font-size: 16px;
}

html, body, #root {
  height: 100%;
}
/*********
theme styles
*********/

.styles_theme__1ZHC6 {
    --primary-color: #31a5ff;
    /* default color*/
    --max-width: 1rem;
    --max-height: 1rem;
    --min-width: 1rem;
    --min-height: 1rem;
    --width: 100%;
    --heigth: 100%;
    --background-color: white;
    --padding-top: 1.75rem;
    --padding-rl: 1.88rem;
    --padding-bottom: 1.125rem;
    font-family: 'Roboto', sans-serif;
    font-family: var(--font-family);
    width: var(--width);
    min-width: 250px;
    height: var(--heigth);
    display: flex;
    flex-direction: column;
    direction: ltr;
    -webkit-tap-highlight-color: transparent;
}

.styles_theme__1ZHC6 ::-webkit-scrollbar {
    width: 10px;
}

.styles_theme__1ZHC6 ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdbdb;
}

.styles_theme__1ZHC6 ::-webkit-scrollbar-track {
    background-color: transparent;
}

.styles_theme__1ZHC6 input[type=number] {
    -moz-appearance: textfield;
}

.styles_theme__1ZHC6 input[type=number]::-webkit-outer-spin-button, .styles_theme__1ZHC6 input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*********
general styles
*********/

.styles_view__3KUcv {
    --padding: var(--padding-top) var(--padding-rl) var(--padding-bottom) var(--padding-rl);
    --component-padding: 0.81rem;
    --component-content-spacing: var(--component-padding);
    --component-content-height: 1.27rem;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius) var(--border-radius) 0px 0px;
    background-color: var(--background-color);
    width: 100%;
    height: 100%;
    font-size: 1rem;
}

/*********
components
*********/

/*-------------------body layot-------------------*/

.styles_body__2c4kT {
    --body-space-childs: 1.25rem;
    /* var(--padding-bottom); */
    display: flex;
    flex-direction: column;
    padding: var(--body-space-childs) var(--padding-rl);
    padding-bottom: 0px;
    /*margin bottom set in body:after*/
    overflow: auto;
    flex-grow: 1;
}

/*workaround to fix this https://bugzilla.mozilla.org/show_bug.cgi?id=748518*/

.styles_body__2c4kT:after {
    content: '';
    display: block;
    padding-bottom: var(--body-space-childs);
}

.styles_body--full_screen___d9d_:after {
    content: none;
}

.styles_body__child__16rp6+.styles_body__child__16rp6 {
    margin-top: var(--body-space-childs);
}

.styles_body__child__16rp6 {
    flex-shrink: 0;
}

.styles_row-fields__Qcck9 {
    display: flex;
    flex-direction: row;
}

/* .row-fields__child {

} */

.styles_row-fields__child__3QV2B+.styles_row-fields__child__3QV2B {
    margin-left: var(--body-space-childs);
}

.styles_push-bottom__BxuQ2 {
    margin-top: auto !important;
}

.styles_grow__2_vwt {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

@media (max-width: 410px) {
    .styles_row-fields__Qcck9 {
        flex-direction: column;
        align-items: stretch;
    }
    .styles_row-fields__child__3QV2B+.styles_row-fields__child__3QV2B {
        margin-left: 0;
        margin-top: var(--body-space-childs);
    }
}

/*******/

.styles_input-icon__TCIYf {
    height: 0.75rem;
}

.styles_button--link__3yQXJ {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: inherit;
    text-decoration: none;
    outline: 0;
}

.styles_button--link__3yQXJ:hover {
    text-decoration: underline;
}

.styles_text--link___7KMv {
    text-decoration: none;
    color: #083d8d;
    cursor: pointer;
}

/* help */

.styles_help-title__bykw6 {
    margin: 0px;
}

.styles_help-img__3vdEx {
    width: 100%;
    margin-bottom: 1rem;
}

.styles_help-text__1deFH {
    font-size: 0.875rem;
}

/******
fix injected fields widths
******/

iframe {
    width: 100%;
}

.styles_input-icon__TCIYf {
    height: 0.75rem;
}

iframe[width="0"][height="0"] {
    position: absolute;
}

.styles_label__1uCk8 {
    font-size: .80rem;
}
/*------------------inputs--------------------*/
/*container label+type*/
.styles_input__3J2I6 {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    min-width: 6rem;
    width: 100%;
}

.styles_input__3J2I6 > label {
    margin-bottom: 0.56rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.styles_label__title__2MD-i {
    margin-right: auto;
}

.styles_label__symbol__2bjCe {
    font-size: 0.875em;
}

.styles_label__symbol__2bjCe:hover {
    cursor: pointer;
    text-decoration: underline;
}

.styles_label__symbol--selected__3ELyY {
    font-weight: 700;
}

.styles_label__symbol--selected__3ELyY:hover {
    cursor: unset;
    text-decoration: unset;
}

/*common to all input types*/
.styles_input__type__35wx- {
    background-color: white;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #DEDEDE;
    padding: var(--component-padding);
    display: flex;
    flex-direction: row;
    height: var(--component-content-height);
    align-items: center;
}

/*apply space between input__type childs*/
.styles_input__type__child__2ZL9Q+.styles_input__type__child__2ZL9Q {
    margin-left: var(--component-content-spacing);
}

.styles_input__type__child--new-first__2lRdi {
    margin-left: 0!important;
}

.styles_input__type__child--old-first__3fzAt {
    margin-left: var(--component-content-spacing);
}

/***input type=selector***/
.styles_input__type--selector__3NorF {
    cursor: pointer;
    align-items: center;
}

.styles_input__type--selector__3NorF span{
    flex-grow: 1;
}

.styles_input__type--selector--disabled__3EjWA {
    cursor: unset;
}

/***input type=number***/
.styles_input__type--number--error__1CH7b,
.styles_input__type--selector--error__2LkxO {
    border-color: #E35757;
}

.styles_input__type--number--disabled__3ONOs {
    background-color: #F8F8F8;
}

.styles_input__type--number__1ESch input {
    outline: none;
    font-size: 1rem;
    border: 0;
    width: 100%;
    background-color: transparent;
    color: inherit;
}

.styles_symbol__t0h1g::before {
    position: relative;
    top: -0.025rem;
    content: attr(before-content);
    align-self: center;
    white-space: nowrap;
    font-size: 0.75em;
}

.styles_symbol__t0h1g::after {
    position: relative;
    top: -0.025rem;
    content: attr(after-content);
    align-self: center;
    white-space: nowrap;
    font-size: 0.75em;
}

.styles_symbol__t0h1g {
    display: inline-flex;
    flex-grow: 1;
}

.styles_input__icon__387Gg {
    height: var(--component-content-height);
}

.styles_input__icon--chevron__1F8_Q {
    height: 0.75rem;
}

.styles_input__icon--date__cZvvF {
    height: 1rem;
}

.styles_clickable-icon__VEpFT:hover {
    cursor: pointer;
}

.styles_text-error__2FM-f {
    color: #E35757;
    font-size: 0.75em;
    margin-top: 0.2727em;
}

.styles_text-hint__2Ll6g {
    color: inherit;
    font-size: 0.75em;
    margin-top: 0.2727em;
}

.styles_text-hint--link__3flQ7 {
    text-decoration: underline;
    cursor: pointer;
    color: #4c4c4c;
    margin-top: 6px;
}

/********error display transition*********/
.styles_collapse-enter__3zuuM {
    max-height: 0px;
    opacity: 0;
}

.styles_collapse-enter-active__WBVoY {
    max-height: 42px;
    opacity: 1;
    transition: max-height 100ms linear, opacity 100ms linear;
}

.styles_collapse-exit__2xYvX {
    max-height: 42px;
    opacity: 1;
}

.styles_collapse-exit-active__1RsIn {
    max-height: 0px;
    opacity: 0;
    transition: max-height 100ms linear, opacity 100ms linear;
}

/*select**/

select {
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 0;
    background-color: transparent;
    color: inherit;
    border:none;
}
/*-------------------button-------------------*/

.styles_button-action__AXP9w {
    outline: none;
    cursor: pointer;
    background-color: var(--primary-color);
    border-style: solid;
    border-radius: 5px;
    border-width: 0px;
    border-color: #DEDEDE;
    padding: 0.78rem;
    color: white;
    font-size: 1.25rem;
    width: 100%;
    margin-top: auto;
}

.styles_button-action--disabled__CaDAA {
    background-color: darkgray;
    cursor: unset;
}
